/* eslint-disable max-params, sort-keys */
/* eslint-disable no-alert */

import API from "./API";

export default class CartAPI {

  /* eslint-disable no-undef */
  static addToCart (productDescriptorId, quantity, storeKind, handleCartNotification = null) {
    return $.ajax({
      "data": {
        "campaign_id": getCookie("campaign_id"),
        "cart_item": {
          "product_descriptor_id": productDescriptorId,
          "store_kind": storeKind
        },
        quantity,
        "template_id": getCookie("template_id")
      },
      "headers": {
        "Authorization": `Token token=${API.API_TOKEN}`,
        "X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")
      },
      "method": "POST",
      "url": `/api/v${API.API_VERSION}/carts/add_item_to_cart`
    }).done((data) => {
      if (data.error) {
        handleCartNotification(data.error);
      } else {
        if (handleCartNotification) {
          handleCartNotification("Added to cart");
        }
        console.log("Added to cart successfully called from API.js");

      }
    }).fail(() => {
      alert("error!");
    });
  }
  /* eslint-enable no-undef */

  static removeFromCart (productDescriptorId, quantity, storeKind) {
    return $.ajax({
      "data": {
        "cart_item": {
          "product_descriptor_id": productDescriptorId,
          "store_kind": storeKind
        },
        quantity
      },
      "headers": {
        "Authorization": `Token token=${API.API_TOKEN}`,
        "X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")
      },
      "method": "POST",
      "url": `/api/v${API.API_VERSION}/carts/remove_item_from_cart`
    }).done(() => {
      console.log("Removed from cart successfully called from API.js");
    }).fail(() => {
      alert("error!");
    });
  }

  static getCartInfo (promoCode = null) {
    return $.ajax({
      "headers": {
        "Authorization": `Token token=${API.API_TOKEN}`,
        "X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")
      },
      "method": "GET",
      "url": `/api/v${API.API_VERSION}/carts/show${promoCode ? `?promo_code=${promoCode}` : ""} `
    }).done(() => {
      console.log("Get cart info successfully called from API.js");
    });
  }

  /* eslint-disable no-undef */
  static purchaseCart (promoCode = null) {
    return $.ajax({
      "data": {
        "campaign_id": getCookie("campaign_id"),
        "promo_code": promoCode,
        "template_id": getCookie("template_id")
      },
      "headers": {
        "Authorization": `Token token=${API.API_TOKEN}`,
        "X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")
      },
      "method": "POST",
      "url": `/api/v${API.API_VERSION}/carts/purchase_cart`
    }).done(() => {
      console.log("Purchase cart successfully called from API.js");
    });
  }
  /* eslint-enable no-undef */

  static updateShippingAddress (addressInfo) {
    return $.ajax({
      "data": {
        "contact": {
          "city": addressInfo.city,
          "first_name": addressInfo.first_name,
          "last_name": addressInfo.last_name,
          "phone": addressInfo.phone,
          "state": addressInfo.state,
          "street_address_1": addressInfo.street_address_1,
          "street_address_2": addressInfo.street_address_2,
          "zip": addressInfo.zip
        }
      },
      "headers": {
        "Authorization": `Token token=${API.API_TOKEN}`,
        "X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")
      },
      "method": "POST",
      "url": "/contacts/save_address.json"
    }).done(() => {
      console.log("Update shipping address successfully called from API.js");
    });
  }

  static updateBillingInfo (billingInfo) {
    return $.ajax({
      "data": {
        "billing_function": "only_update",
        "customer": {"recurly_billing_info_token": billingInfo.recurlyToken},
        "from_cart": "true"
      },
      "headers": {
        "Authorization": `Token token=${API.API_TOKEN}`,
        "X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")
      },
      "method": "POST",
      "url": "/billing/update.json"
    }).done(() => {
      console.log("Update billing info successfully called from API.js");
    });
  }

  static fetchPDAvailability (productDescriptorIds, storeKind) {
    return $.ajax({
      "data": {
        "ids": productDescriptorIds,
        "store_kind": storeKind
      },
      "headers": {
        "Authorization": `Token token=${API.API_TOKEN}`,
        "X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")
      },
      "method": "GET",
      "url": `/api/v${API.API_VERSION}/carts/fetch_pd_availability`
    }).done(() => {
      console.log("Fetch PD availability successfully called from API.js");
    });
  }

  static verifyAddress (addressInfo, success, error) {
    return $.post({
      "data": {"contact": this.formatContactParamsForEasyPost(addressInfo)},
      "headers": API.authHeaders(),
      "url": "/contacts/verify_address.json",
      success (response) {
        success(response);
      },
      error
    });
  }

  static formatContactParamsForEasyPost (contactParams) {
    return {
      "city": contactParams.city,
      "email": contactParams.email,
      "state": contactParams.state,
      "street1": contactParams.streetAddress,
      "street2": contactParams.unitNumber,
      "zip": contactParams.zipCode
    };
  }
}
