/* eslint-disable max-params, sort-keys */
/* eslint-disable no-alert */
/* eslint-disable dot-location */

import API from "./API";

export default class NonMemberCartAPI {

  static getItemAvailabilityCount (productDescriptorId, storeKind) {
    return $.ajax({
      "data": {
        "id": productDescriptorId,
        "store_kind": storeKind
      },
      "headers": API.authHeaders(),
      "method": "GET",
      "url": `/api/v${API.API_VERSION}/guest/get_available_item_count`
    })
      .then(response => response)
      .done(() => {
        console.log("Fetch PD availability successfully called from API.js");
      });
  }

  static getCartInfo (promoCode = null) {
    return $.ajax({
      "headers": {"X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")},
      "method": "GET",
      "url": `/api/v${API.API_VERSION}/guest/checkout_preview${promoCode ? `?promo_code=${promoCode}` : ""} `
    });
  }

  static addToCart (productDescriptorId, quantity, storeKind) {
    return $.ajax({
      "data": {
        "cart_item": {
          "product_descriptor_id": productDescriptorId,
          "store_kind": storeKind
        },
        quantity
      },
      "headers": {"X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")},
      "method": "POST",
      "url": `/api/v${API.API_VERSION}/guest/add_to_cart`
    });
  }


  static removeFromCart (productDescriptorId, quantity, storeKind) {
    return $.ajax({
      "data": {
        "cart_item": {
          "product_descriptor_id": productDescriptorId,
          "store_kind": storeKind
        },
        quantity
      },
      "headers": {"X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")},
      "method": "POST",
      "url": `/api/v${API.API_VERSION}/guest/remove_from_cart`
    });
  }

  /* eslint-disable no-undef */
  static purchaseCart (recurlyToken, contactId, recaptchaToken, promoCode = null) {
    return $.ajax({
      "data": {
        "captcha_token": recaptchaToken,
        "contact_id": contactId,
        "recurly_token": recurlyToken,
        "promo_code": promoCode
      },
      "headers": {"X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")},
      "method": "POST",
      "url": `/api/v${API.API_VERSION}/guest/purchase_cart`
    }).done(() => {
      console.log("Purchase cart successfully called from API.js");
    });
  }

  static getStripeSession (sessionId) {
    return $.ajax({
      "data": {"session_id": sessionId},
      "headers": {"X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")},
      "method": "GET",
      "url": `/api/v${API.API_VERSION}/guest/get_stripe_session`
    }).done(() => {
      console.log("Get stripe session successfully called from API.js");
    });
  }

  static expireStripeSession (sessionId) {
    return $.ajax({
      "data": {"session_id": sessionId},
      "headers": {"X-CSRF-Token": document.querySelectorAll("meta[name=csrf-token]")[0].getAttribute("content")},
      "method": "GET",
      "url": `/api/v${API.API_VERSION}/guest/expire_stripe_session`
    }).done(() => {
      console.log("Expire stripe session successfully called from API.js");
    });
  }

  static createContact (contactInfo, success, error) {
    return $.post({
      "data": {"contact": contactInfo},
      "headers": API.authHeaders(),
      "url": `/api/v${API.API_VERSION}/guest/contacts`,
      success (response) {
        success(response);
      },
      error
    });
  }

  static verifyAddress (addressInfo, success, error) {
    return $.post({
      "data": {"contact": this.formatContactParamsForEasyPost(addressInfo)},
      "headers": API.authHeaders(),
      "url": `/api/v${API.API_VERSION}/guest/contacts/verify_address`,
      success (response) {
        success(response);
      },
      error
    });
  }

  static updateContact (contactId, contactInfo, success, error) {
    return $.ajax({
      "data": {"contact": this.formatContactParams(contactInfo)},
      "headers": API.authHeaders(),
      "type": "PUT",
      "url": `/api/v${API.API_VERSION}/guest/contacts/${contactId}`,
      success (response) {
        success(response);
      },
      error
    });
  }

  static formatContactParamsForEasyPost (contactParams) {
    return {
      "city": contactParams.city,
      "email": contactParams.email,
      "state": contactParams.state,
      "street1": contactParams.streetAddress,
      "street2": contactParams.unitNumber,
      "zip": contactParams.zipCode
    };
  }
}
