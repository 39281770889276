/* eslint-disable sort-vars, one-var, complexity, max-lines */

import {
  ADD_PROMOTION,
  CLEAR_ALL_FILTERS,
  CLEAR_BIN_ELIGIBILITY,
  FETCH_BIN_ELIGIBILITY,
  FETCH_CART_ITEMS,
  FETCH_CUSTOMER_CREDITS,
  FETCH_CUSTOMER_REQUESTS,
  FETCH_ORDER_INFO,
  FETCH_PD_PRICES,
  FETCH_REWARDS_DISCOUNTS,
  HANDLE_FILTER_CLICK,
  HIDE_ERROR,
  REMOVE_PROMOTION,
  SET_DISPLAY_NEW_TIER_NOTIFICATION,
  SET_MY_SET_SELECTED_PRODUCTS,
  SET_NOT_PURCHASED_PRODUCTS,
  SET_OFFSET,
  SET_POST_PURCHASE_MODAL,
  SHOW_ERROR,
  UPDATE_CREDIT,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_PRODUCT_BEING_SWAPPED
} from "../actions/index";
import _flatten from "lodash/flatten";
import _keyBy from "lodash/keyBy";
import _remove from "lodash/remove";

const feedbackShipmentUpdate = (prevState, action) => { // eslint-disable-line
  const {feedbackType, productDescriptorId, score} = action.payload,
    setRatings = prevState.ratings,
    pdRatings = setRatings[productDescriptorId], // eslint-disable-line
    nextPdRatings = Object.assign({...pdRatings}, {[feedbackType]: score}), // eslint-disable-line
    nextSetRatings = Object.assign({...setRatings}, {[productDescriptorId]: nextPdRatings});
  return Object.assign({...prevState}, {"ratings": nextSetRatings});
};

const feedbackCommentUpdate = (prevState, action) => { // eslint-disable-line
  const {comment, productDescriptorId} = action.payload,
    setComments = prevState.comments,
    nextSetComments = Object.assign({...setComments}, {[productDescriptorId]: comment});
  return Object.assign({...prevState}, {"comments": nextSetComments});
};

const feedbackRequestUrlUpdate = (prevState, action) => {
  const {requestUrl} = action.payload;
  return Object.assign({...prevState}, {requestUrl});
};

const removeDefaultHide = filters =>
  filters.filter((fil) => {
    if (!fil.defaultHide) {
      return true;
    }
    const flattened = _flatten(filters.map(filter => filter.showIfSelected).filter(sel => sel));
    return flattened.includes(fil.key.split("-")[0]);
  });

const filtersUpdate = (prevState = [], action) => {
  const {key, singleSelection} = action.payload;

  if (prevState.map(filter => filter.key).includes(key)) {
    return removeDefaultHide(prevState.filter(filter => filter.key.indexOf(key) === -1));
  }

  let newState = prevState;
  if (singleSelection) {
    const filtersArray = key.split("-");
    if (filtersArray.length === 2) {
      newState = newState.filter(fil => fil.key.indexOf(filtersArray[0]) === -1);
    } else {
      filtersArray.pop();
      const parentKeys = filtersArray.map((fil, ind) => filtersArray.slice(0, ind + 1).join("-"));
      newState = newState.filter(fil => fil.key.indexOf(filtersArray[0]) === -1 || parentKeys.includes(fil.key));
    }
  }
  return removeDefaultHide(
    [
      action.payload,
      ...newState
    ]
  );
};

const fetchCartItems = (prevState, cartItems) => ({
  ...prevState,
  cartItems
});

const fetchOrderInfo = (prevState, orderInfo) => {
  const prevOrderInfos = prevState.orderInfo,
    newOrderInfo = _keyBy([orderInfo], "product_descriptor_id");
  return {
    ...prevState,
    "orderInfo": {
      ...prevOrderInfos,
      ...newOrderInfo
    }
  };
};

const setBuyItNowEligibility = (prevState, eligibility) => (
  {
    ...prevState,
    "buyItNowEligible": eligibility.binEligible
  }
);

const fetchRewardsDiscounts = (prevState, rewardsDiscounts) => {
  const {discounts, "invoice_label": invoiceLabel} = rewardsDiscounts;

  return {
    ...prevState,
    "rewardsDiscounts": {...discounts},
    "rewardsInvoiceLabel": invoiceLabel
  };
};

const fetchPDPrices = (prevState, prices) => {
  const prevPrices = prevState.pdPrices;
  return {
    ...prevState,
    "pdPrices": {
      ...prevPrices,
      ...prices
    }
  };
};

const fetchCustomerRequests = (prevState, requests) =>
  (
    {
      ...prevState,
      "customerRequests": {...requests}
    }
  );

const updateCustomerRequest = (prevRequests, request) => {
  if (prevRequests && prevRequests[request.id]) {
    if (prevRequests[request.id].statuses.includes("pending")) {
      if (request.value === "fulfilled") {
        prevRequests[request.id].statuses = prevRequests[request.id].statuses.concat("fulfilled");
      }
      _remove(prevRequests[request.id].statuses, status => status === "pending");
    } else {
      prevRequests[request.id].statuses = prevRequests[request.id].statuses.concat("pending");
    }
    return {...prevRequests};
  }
  return {
    ...prevRequests,
    [request.id]:
      {
        "prioritized": false,
        "statuses": [request.value]
      }
  };
};

const fetchCustomerCredits = (prevState, credits) =>
  (
    {
      ...prevState,
      "customerCredits": {...credits}
    }
  );

const showError = (prevState, errorMessage) => ({
  ...prevState,
  "error": {
    "displayError": true,
    errorMessage
  }
});

const hideError = prevState => (
  {
    ...prevState,
    "error": {
      "displayError": false,
      "errorMessage": ""
    }
  }
);

const initialState = {"swapped": false};

export default (state = {initialState}, action) => {
  switch (action.type) {
  case "REINITIALIZE_STORE":
    return Object.assign({...state}, action.payload);
  case "FEEDBACK_SHIPMENT_UPDATE":
    return Object.assign({...state}, {"Feedback": feedbackShipmentUpdate(state.Feedback, action)});
  case "FEEDBACK_COMMENT_UPDATE":
    return Object.assign({...state}, {"Feedback": feedbackCommentUpdate(state.Feedback, action)});
  case "FEEDBACK_URL_UPDATE":
    return Object.assign({...state}, {"Feedback": feedbackRequestUrlUpdate(state.Feedback, action)});
  case HANDLE_FILTER_CLICK:
    return {
      ...state,
      "selectedFilters": filtersUpdate(state.selectedFilters, action)
    };
  case CLEAR_ALL_FILTERS:
    return {
      ...state,
      "selectedFilters": []
    };
  case UPDATE_CREDIT:
    return {
      ...state,
      "credit": action.payload
    };
  case SET_OFFSET:
    return {
      ...state,
      "offset": action.payload
    };
  case "SET_FILTERS":
    return {
      ...state,
      "selectedFilters": action.selectedFilters
    };
  case SET_MY_SET_SELECTED_PRODUCTS:
    return {
      ...state,
      "mySetSelectedProducts": action.payload
    };
  case SET_NOT_PURCHASED_PRODUCTS:
    return {
      ...state,
      "notPurchasedCache": action.payload
    };
  case FETCH_CART_ITEMS:
    return fetchCartItems(state, action.payload);
  case FETCH_ORDER_INFO:
    return fetchOrderInfo(state, action.payload);
  case FETCH_BIN_ELIGIBILITY:
    return setBuyItNowEligibility(state, action.payload);
  case CLEAR_BIN_ELIGIBILITY:
    return setBuyItNowEligibility(state, action.payload);
  case FETCH_CUSTOMER_REQUESTS:
    return fetchCustomerRequests(state, action.payload);
  case UPDATE_CUSTOMER_REQUEST:
    return {
      ...hideError(state),
      "customerRequests": updateCustomerRequest(state.customerRequests, action.payload)
    };
  case FETCH_PD_PRICES:
    return fetchPDPrices(state, action.payload);
  case FETCH_REWARDS_DISCOUNTS:
    return fetchRewardsDiscounts(state, action.payload.data);
  case UPDATE_PRODUCT_BEING_SWAPPED:
    return {
      ...hideError(state),
      "productToSwapOut": action.payload
    };
  case ADD_PROMOTION:
    return {
      ...state,
      "promoCode": action.payload.code,
      "promotions": [
        ...state.promotions,
        action.payload
      ]
    };
  /* eslint-disable no-undef */
  case REMOVE_PROMOTION:
    return {
      ...state,
      "promoCode": "",
      "promotions": [
        ...state.promotions.filter(promotion =>
          promotion.code?.toLowerCase() !== action.payload.toLowerCase())
      ]
    };
  /* eslint-enable no-undef */
  case SET_DISPLAY_NEW_TIER_NOTIFICATION:
    return {
      ...state,
      "displayNewTierNotification": action.payload
    };
  case SET_POST_PURCHASE_MODAL:
    return {
      ...state,
      "postPurchaseModal": action.payload
    };
  case FETCH_CUSTOMER_CREDITS:
    return fetchCustomerCredits(state, action.payload);
  case SHOW_ERROR:
    return showError(state, action.payload.errorMessage);
  case HIDE_ERROR:
    return state.error.displayError === true ? hideError(state) : {...state};
  default:
    return {...state};
  }
};
